/*=============================================
=            flaticon            =
=============================================*/

@font-face {
    font-family: "Flaticon";
    src: url("../../fonts/Flaticon.eot");
    src: url("../../fonts/Flaticon.eot") format("embedded-opentype"), url("../../fonts/Flaticon.woff") format("woff"), url("../../fonts/Flaticon.ttf") format("truetype"), url("../../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: 400;
    font-style: normal
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../../fonts/Flaticon.svg#Flaticon") format("svg")
    }
}

[class^=flaticon-]:before,
[class*=" flaticon-"]:before,
[class^=flaticon-]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.flaticon-001-worker-1:before {
    content: "\f100"
}

.flaticon-002-welding:before {
    content: "\f101"
}

.flaticon-003-waste:before {
    content: "\f102"
}

.flaticon-004-walkie-talkie:before {
    content: "\f103"
}

.flaticon-005-valve:before {
    content: "\f104"
}

.flaticon-006-truck:before {
    content: "\f105"
}

.flaticon-007-tools:before {
    content: "\f106"
}

.flaticon-008-machine-1:before {
    content: "\f107"
}

.flaticon-009-storage:before {
    content: "\f108"
}

.flaticon-010-tank-1:before {
    content: "\f109"
}

.flaticon-011-siren:before {
    content: "\f10a"
}

.flaticon-012-scheme:before {
    content: "\f10b"
}

.flaticon-013-danger:before {
    content: "\f10c"
}

.flaticon-014-robot-arm:before {
    content: "\f10d"
}

.flaticon-015-cart:before {
    content: "\f10e"
}

.flaticon-016-gear:before {
    content: "\f10f"
}

.flaticon-017-pump:before {
    content: "\f110"
}

.flaticon-018-power-tower:before {
    content: "\f111"
}

.flaticon-019-power-press:before {
    content: "\f112"
}

.flaticon-020-planning:before {
    content: "\f113"
}

.flaticon-021-worker:before {
    content: "\f114"
}

.flaticon-022-tank:before {
    content: "\f115"
}

.flaticon-023-microprocessor:before {
    content: "\f116"
}

.flaticon-024-statistics:before {
    content: "\f117"
}

.flaticon-025-meter:before {
    content: "\f118"
}

.flaticon-026-mechanism:before {
    content: "\f119"
}

.flaticon-027-material:before {
    content: "\f11a"
}

.flaticon-028-manufacturing-plant:before {
    content: "\f11b"
}

.flaticon-029-manufacturing:before {
    content: "\f11c"
}

.flaticon-030-management:before {
    content: "\f11d"
}

.flaticon-031-machine:before {
    content: "\f11e"
}

.flaticon-032-gears-1:before {
    content: "\f11f"
}

.flaticon-033-laser:before {
    content: "\f120"
}

.flaticon-034-industrial-robot:before {
    content: "\f121"
}

.flaticon-035-parcel:before {
    content: "\f122"
}

.flaticon-036-gears:before {
    content: "\f123"
}

.flaticon-037-forklift:before {
    content: "\f124"
}

.flaticon-038-food:before {
    content: "\f125"
}

.flaticon-039-factory-1:before {
    content: "\f126"
}

.flaticon-040-factory:before {
    content: "\f127"
}

.flaticon-041-eco:before {
    content: "\f128"
}

.flaticon-042-monitor:before {
    content: "\f129"
}

.flaticon-043-wheel:before {
    content: "\f12a"
}

.flaticon-044-conveyor:before {
    content: "\f12b"
}

.flaticon-045-controller:before {
    content: "\f12c"
}

.flaticon-046-control-system:before {
    content: "\f12d"
}

.flaticon-047-control-lever:before {
    content: "\f12e"
}

.flaticon-048-chemical:before {
    content: "\f12f"
}

.flaticon-049-container:before {
    content: "\f130"
}

.flaticon-050-boxes:before {
    content: "\f131"
}

/*=====  End of flaticon  ======*/